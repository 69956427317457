import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEntry, createEntry, updateEntry } from "./apiClient";

const EntryForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    if (id) {
      fetchEntry(id)
        .then((entry) => {
          setKey(entry.key);
          setValue(entry.value);
        })
        .catch((error) => console.error("Error fetching entry:", error));
    }
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const entry = { key, value };

    if (id) {        
      updateEntry(id, entry)
        .then(() => navigate("/"))
        .catch((error) => console.error("Error updating entry:", error));
    } else {        
      createEntry(entry)
        .then(() => navigate("/"))
        .catch((error) => console.error("Error creating entry:", error));
    }
  };

  return (
    <div>
      <h1>{id ? "Edit Entry" : "Create New Entry"}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Key:</label>
          <input
            type="text"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Value:</label>
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            required
            style={{ width: "100%", height: "100px" }}
          />
        </div>
        <button type="submit">{id ? "Update" : "Create"}</button>
      </form>
      <button onClick={() => navigate("/")}>Cancel</button>
    </div>
  );
};

export default EntryForm;