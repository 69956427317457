const API_BASE_URL = "http://tonko2.ddns.net:8081";
//const API_BASE_URL = "https://localhost:8443";

export const fetchEntries = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/keys`);
    if (!response.ok) {
      throw new Error("Failed to fetch entries");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching entries:", error);
    throw error;
  }
};

export const fetchEntry = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/key/${id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch entry");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching entry:", error);
    throw error;
  }
};

export const createEntry = async (entry) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Content-Type を追加
      },
      body: JSON.stringify(entry), // データを JSON 形式で送信
    });

    if (!response.ok) {
      throw new Error("Failed to create entry");
    }
  } catch (error) {
    console.error("Error creating entry:", error);
    throw error;
  }
};

export const updateEntry = async (id, entry) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/key/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json", // Content-Type を追加
      },
      body: JSON.stringify(entry), // データを JSON 形式で送信
    });

    if (!response.ok) {
      throw new Error("Failed to update entry");
    }
  } catch (error) {
    console.error("Error updating entry:", error);
    throw error;
  }
};

export const deleteEntry = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/key/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("Failed to delete entry");
    }
  } catch (error) {
    console.error("Error deleting entry:", error);
    throw error;
  }
};
