import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchEntries, deleteEntry } from "./apiClient";
import EntryForm from "./EntryForm";
import MarkdownPreview from "./MarkdownPreview";
import "./App.css";

const App = () => {
  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    fetchEntries()
      .then((data) => setEntries(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCreate = () => {
    navigate("/create");
  };

  const handleEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  const handleDelete = (id) => {
    deleteEntry(id)
      .then(() => {
        setEntries((prevEntries) =>
          prevEntries.filter((entry) => entry.id !== id)
        );
        setCurrentPage(1);
      })
      .catch((error) => console.error("Error deleting entry:", error));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = entries.slice(indexOfFirstEntry, indexOfLastEntry);

  return (
    <div>
      <h1 className="centered">ChatGPT History</h1>
      <button onClick={() => handleCreate()}>Create</button>
      {currentEntries.map((entry) => (
        <div
          key={entry.id}
          style={{
            border: "1px solid black",
            padding: "10px",
            paddingBottom: "30px",
            margin: "10px 0",
            position: "relative", // 親要素を相対位置に設定
          }}
        >
          <p style={{ margin: 0 }}>
            <strong>{entry.key}:</strong>
          </p>
          <MarkdownPreview>{entry.value}</MarkdownPreview>
          <div
            style={{
              position: "absolute", // 絶対位置に設定         
              right: "90px",              
              display: "flex",
              bottom: "5px",
              justifyContent: "flex-end", // 右端に揃える
              gap: "5px",
            }}
          >
            <button onClick={() => handleEdit(entry.id)}>Edit</button>
            <button onClick={() => handleDelete(entry.id)}>Delete</button>
          </div>
        </div>
      ))}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {[...Array(Math.ceil(entries.length / entriesPerPage)).keys()].map(
          (page) => (
            <button
              key={page + 1}
              onClick={() => handlePageChange(page + 1)}
              style={{ margin: "0 5px" }}
            >
              {page + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

const AppWithRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/create" element={<EntryForm />} />
        <Route path="/edit/:id" element={<EntryForm />} />
      </Routes>
    </Router>
  );
};

export default AppWithRouter;
