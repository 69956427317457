import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Markdownプレビューコンポーネント
const MarkdownPreview = ({ children }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div
        style={{
          flex: 1,
          height: expanded ? 'auto' : '100px',
          overflowY: 'auto',
          border: '1px solid #ddd',
          padding: '10px',
          boxSizing: 'border-box',
          background: '#f9f9f9',
        }}
      >
        <ReactMarkdown
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');

              return !inline && match ? (
                <SyntaxHighlighter
                  style={solarizedlight}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {children}
        </ReactMarkdown>
      </div>
      <button
        onClick={() => setExpanded(!expanded)}
        style={{
          marginLeft: '10px',
          alignSelf: 'flex-start',
        }}
      >
        {expanded ? 'Collapse' : 'Expand'}
      </button>
    </div>
  );
};

export default MarkdownPreview;
